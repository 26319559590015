import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { AppCommonService } from '../app-common.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
  public breadCrumbs = false;
  @SessionStorage('auth') public user!: {
    userDetails: { role: any; fullName: any };
  };
  @SessionStorage('previousUrl') public previousUrl!: any;
  public routingUrl = ['/visitors-list'];
  public loggedInUserName = this.user?.userDetails?.role;
  public fullName = this.user?.userDetails?.fullName;

  constructor(
    private router: Router,
    private appCommonService: AppCommonService) {
    this.appCommonService.login.subscribe((res: boolean) => {
      if (res) {
        this.fullName = this.user?.userDetails?.fullName;
        this.loggedInUserName = this.user?.userDetails?.role
      }
    });
    this.router.events.subscribe((response) => {
      if (
        response instanceof NavigationStart ||
        response instanceof NavigationEnd
      ) {
        if (
          response.url.includes('forms') ||
          response.url.includes('vendor-form') ||
          response.url.includes('key-issuance-form')
        ) {
          this.breadCrumbs = true;
          console.log('response ', response);
          console.log('url ==> ', response.url, response.url.split('/'));
          if (
            response.url.includes('visitors-list') &&
            response.url.split('/').length == 5
          ) {
            console.log(
              'this.previousUrl ',
              this.previousUrl,
              this.previousUrl.split('/')
            );
            this.routingUrl = [
              `/${this.previousUrl.split('/')[1]}`,
              `${this.previousUrl.split('/')[2]}`,
              this.previousUrl.split('/')[3],
            ];
          } else {
            this.routingUrl = ['/visitors-list'];
          }
          console.log('this.routingUrl ', this.routingUrl);
        } else {
          this.breadCrumbs = false;
        }
      }
    });
  }

  ngOnInit(): void { }
}
